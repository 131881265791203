import openKustomer from '@src/functions/kustomer';
import classes from '@src/styles/BottomCTA.module.css'
import Link from 'next/link'
import { useRouter } from 'next/router';

type BottomCTAProps = { isProviderPortal?: boolean; isB2b?: boolean }

function BottomCTA({ isProviderPortal = false, isB2b = false }: BottomCTAProps) {
  const router = useRouter();
  const { gclid, keyword, location } = router.query;
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.content}>
          <h4 className={classes.topHeading}>Let&apos;s Get Started</h4>
          <h2 className={classes.heading}>
            {isB2b ? (
              <>
                Are You Ready
                <br />
                to Solve Your Waste
                <br />
                Management
                <br />
                Worries?
              </>
            ) : (
              <>
                Radically
                <br />
                Simplified Waste
                <br />
                Management
              </>
            )}
          </h2>
        </div>
        <div className={classes.content}>
          {isProviderPortal ? (
            <Link className={classes.primaryButton} href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}?login=1&gclid=${gclid ?? ""}`}>
              Sign Up Now
            </Link>
          ) : (
            <Link href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&gclid=${gclid ?? ""}&keyword=${keyword ?? ""}&location=${location ?? ""}&redirect=1`} className={classes.primaryButton}>
              Book Now
            </Link>
          )}
          <button className={classes.secondaryButtonDark} onClick={openKustomer}>
            Chat With Us
          </button>
        </div>
      </div>
    </section>
  )
}

export default BottomCTA
