import React from 'react'
import classes from '@src/styles/HowWeWork.module.css'
import classNames from 'classnames'
import howWeWork from '@src/assets/how-we-work.jpg'
import BusinessIcon from '@src/assets/business-icon.svg'
import IndividualIcon from '@src/assets/individual-icon.svg'
import Link from 'next/link'
import { segmentTrack } from '@src/functions/segment'
import openKustomer from '@src/functions/kustomer'
// import { KustomerLauncher } from '../../../containers/KustomerLauncher'

function HowWeWork() {
  const handleBookNow = (tracker: string) => {
    segmentTrack('Click', {
      button: tracker,
    })
  }
  return (
    <>
      <section className={classes.container}>
        <div className={classes.mainContent}>
          <div style={{ backgroundImage: `url(${howWeWork.src})` }} className={classes.image} />
          <div className={classes.textContainer}>
            <h2 className={classNames(classes.heading, classes.topHeading)}>How Dimension Works</h2>
            <h2 className={classNames(classes.heading, classes.mainHeading)}>
              Solving your waste management woes is as simple as 1,2,3!
            </h2>
            <div className={classes.stepWrapper}>
              <ul className={classes.StepProgress}>
                <li className={classes.StepProgressItem}>
                  <div className={classes.stepContent}>
                    <p className={classes.description}>
                      <strong>Enter your ZIP code</strong>, then choose
                      <br className={classes.break} /> a convenient time and date for pickup
                    </p>
                    <span className={classes.stepNumber}>01</span>
                  </div>
                </li>
                <li className={classes.StepProgressItem}>
                  <div className={classes.stepContent}>
                    <p className={classes.description}>
                      <strong>Get</strong> an instant, upfront <strong>quote</strong>
                    </p>
                    <span className={classes.stepNumber}>02</span>
                  </div>
                </li>
                <li className={classes.StepProgressItem}>
                  <div className={classes.stepContent}>
                    <p className={classes.description}>
                      <strong>Make a booking</strong> in seconds
                    </p>
                    <span className={classes.stepNumber}>03</span>
                  </div>
                </li>
              </ul>
            </div>
            <h4 className={classNames(classes.heading, classes.smallHeading)}>
              It&apos;s that easy!{' '}
            </h4>
            <p className={classes.description}>
              Once you&apos;ve made your booking, we&apos;ll provide you with the contact
              information of your reliable waste management provider. If you have any questions or
              want to make a change, you can easily contact us directly by SMS, call or email.
            </p>
          </div>
        </div>
      </section>
      <section className={classNames(classes.container, classes.boxSection)}>
        <div className={classes.mainContent}>
          <h4 className={classNames(classes.heading, classes.secondaryHeading)}>Try It Now</h4>
          <div className={classes.boxContainer}>
            <div className={classes.box}>
              <div className={classes.boxContent}>
                <img alt='' className={classes.boxIcon} src={BusinessIcon.src} />
                <h4 className={classNames(classes.heading, classes.extraSmallHeading)}>Business</h4>
                <Link
                  href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&business_client=1&redirect=1`}
                  className={classes.primaryButton}
                  onClick={() => handleBookNow('Try It Now - Business')}
                >
                  Book Now
                </Link>
              </div>
            </div>
            <div className={classes.box}>
              <div className={classes.boxContent}>
                <img alt='individual' className={classes.boxIcon} src={IndividualIcon.src} />
                <h4 className={classNames(classes.heading, classes.extraSmallHeading)}>
                  Individual
                </h4>
                <Link
                  href='booking?step=initial'
                  className={classes.primaryButton}
                  onClick={() => handleBookNow('Try It Now - Individual')}
                >
                  Book Now
                </Link>
              </div>
            </div>
            <div className={classNames(classes.box, classes.boxFilled)}>
              <div className={classes.boxContent}>
                <h4 className={classNames(classes.heading, classes.extraSmallHeading)}>
                  Complex tasks
                  <br />
                  that need a custom
                  <br />
                  solution?
                </h4>
                <button style={{ width: '171px', marginTop: 12 }} className={classes.primaryButton} onClick={openKustomer}>
                  <p>Chat with us</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HowWeWork
