import React from 'react'
import classes from '@src/styles/ProductFeatures.module.css'
import feature1 from '@src/assets/features/feature_1.jpg'
import feature2 from '@src/assets/features/feature_2.jpg'
import feature3 from '@src/assets/features/feature_3.png'
import feature4 from '@src/assets/features/feature_4.jpg'
import Link from 'next/link'
import openKustomer from '@src/functions/kustomer'
import FeatureScroller from '../common/components/FeatureScroller'

const features = [
  {
    title: 'Waste Management On-Demand',
    descriptions: [
      'Solve your waste management worries with the click of a button. Our fully digital service makes it simple to organize sustainable pickup for any waste you want to remove and recycle from your site, including: cardboard, bulky items, food waste, medical waste and hazardous waste.',
    ],
    buttons: (gclid: string) => (
      <div className={classes.buttonContainer}>
        <Link href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&redirect=1&gclid=${gclid ?? ""}${process.env.NEXT_PUBLIC_DISCOUNT ?? ""}`} className={classes.primaryButton}>
          Book Now
        </Link>
        <button className={classes.secondaryButton} onClick={openKustomer}>
          <p>Chat with us</p>
        </button>
      </div>
    ),
    imageUrl: feature1.src,
  },
  {
    title: 'Flexible & Customizable',
    descriptions: [
      'Whether you’re scheduling a one-off pickup, searching for a reliable regular provider or solving a waste emergency, we’ll find the solution to perfectly suit your needs.',
    ],
    buttons: (gclid: string) => (
      <div className={classes.buttonContainer}>
        <Link href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&redirect=1&gclid=${gclid ?? ""}${process.env.NEXT_PUBLIC_DISCOUNT ?? ""}`} className={classes.primaryButton}>
          Book Now
        </Link>
        <button className={classes.secondaryButton} onClick={openKustomer}>
          <p>Chat with us</p>
        </button>
      </div>
    ),
    imageUrl: feature2.src,
  },
  {
    title: 'Simplifying Sustainability',
    descriptions: [
      'Dimension offers businesses the tools and data they need to minimise their environmental impact without increasing an already busy workload.',
    ],
    buttons: (gclid: string) => (
      <div className={classes.buttonContainer}>
        <Link href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&redirect=1&business_client=1&gclid=${gclid ?? ""}${process.env.NEXT_PUBLIC_DISCOUNT ?? ""}`} className={classes.primaryButton}>
          Book Now
        </Link>
        <button className={classes.secondaryButton} onClick={openKustomer}>
          <p>Chat with us</p>
        </button>
      </div>
    ),
    imageUrl: feature4.src,
  },
  {
    title: 'Powerful Diversion Data',
    descriptions: [
      'Track exactly where your waste ends up, including receipts, photos and detailed breakdowns of exactly what was hauled. Whether you’re looking to streamline legal compliance or be more environmentally conscious, we’ve got you covered.',
    ],
    buttons: (gclid: string) => (
      <div className={classes.buttonContainer}>
        <Link href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&redirect=1&gclid=${gclid ?? ""}${process.env.NEXT_PUBLIC_DISCOUNT ?? ""}`} className={classes.primaryButton}>
          Book Now
        </Link>
        <button className={classes.secondaryButton} onClick={openKustomer}>
          <p>Chat with us</p>
        </button>
      </div>
    ),
    backgroundColor: '#F1F1F1',
    imageUrl: feature3.src,
  },
]

function ProductFeatures() {
  return (
    <section className={classes.container}>
      <FeatureScroller features={features} />
    </section>
  )
}

export default ProductFeatures
