import Head from 'next/head'
import Pitch from '../../LandingPage/Pitch'
import Clients from '../../LandingPage/Clients'
import ProductFeatures from '../../LandingPage/ProductFeatures'
import HowWeWork from '../../LandingPage/HowWeWork'
import Testimonials from '../../LandingPage/Testimonials'
import Solutions from '../../LandingPage/Solutions'
import CaseStudies from '../../LandingPage/CaseStudies'
import BottomCTA from './BottomCTA'

const typeConfig: any = {
  "dumpster-rental": {
    title: "Affordable Dumpster Rental Services - Short and Long Term Rentals | Trash Warrior",
    description: "Our dumpster rental services provide sustainable and affordable waste management solutions for your home or business. Choose from a variety of sizes to fit your needs and enjoy hassle-free delivery and pick-up. Book online today or contact us for a free quote!",
    heroText: "Dumpster Rental Services",
    subText: "Whether you're cleaning out your home or managing a construction project, we provide affordable dumpster rentals to make waste management simple and stress-free.",
    secondarySubText: "Our short and long term rental options are perfect for a wide range of needs. Book online or contact us today for a free quote!",
  },
  "junk-removal": {
    title: "Same Day Junk Removal Services - Book Online | Trash Warrior",
    description: "Get fast and affordable waste removal, mattress removal and furniture removal services for your home or business. We provide efficient and eco-friendly solutions to dispose of all types of unwanted items. Book online today or contact us for a free quote!",
    heroText: "Junk Removal Services",
    subText: "Do you need to dispose of unwanted items quickly or on a recurring basis? We offer eco-friendly waste removal for mattresses, furniture, and more.",
    secondarySubText: "Our low-cost solutions are perfect for homes and businesses of all sizes. Book online or contact us today for a free quote!",
  },
  "piano-removal": {
    title: "Piano Removal Service - Dispose of Large Items | Dimension formerly Trash Warrior",
    description: "Do you need to remove a piano from your house or workplace? We offer eco-friendly removal services for grand pianos, electric pianos, upright pianos, and more.",
    heroText: "Piano Removal Services",
    subText: "Need to get rid of a piano? Dimension offers reliable removal services to help you dispose of large items such as pianos, couches, and much more. Book online today or contact us for a free quote!",
  },
  "hazardous-waste": {
    title: "Hazardous Waste Disposal Experts - Safe & Eco-Friendly Solutions | Dimension",
    description: "Professional hazardous waste removal services for homes and businesses. We have safe, eco-friendly, and cost effective solutions for chemical, medical, paint, and other hazardous waste disposal. Contact us for a free quote!",
    heroText: "Hazardous Waste Removal",
    subText: "Reliable, Eco-Friendly Solutions for Your Home or Business. Safely Dispose of Any Hazardous Waste with Ease.",
    quoteOnly: true,
    source: "hazardous-waste",
  }
}

export default function HomePage({ type }: any) {

  const content = typeConfig[type] ?? {
    title: "Waste Removal, Mattress Removal and Dumpster Rentals - Same Day Services | Trash Warrior",
    description: "At Dimension, we provide simple and sustainable waste management solutions for homes and businesses across the country. From junk removal and dumpster rentals to recycling and hazardous waste disposal, we offer a range of customizable services to fit your unique needs and budget.",
  };

  return (
    <>
      <Head>
        <title>
          {content.title}
        </title>
        <meta name="description" content={content.description} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      {/* <AuthRedirector /> */}
      <Pitch isBusiness={false} content={content} />
      <Clients />
      <ProductFeatures />
      <HowWeWork />
      <Testimonials />
      <Solutions />
      <CaseStudies />
      <BottomCTA isProviderPortal={false} />
    </>
  )
}
