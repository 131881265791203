import React from 'react'

import classes from '@src/styles/Clients.module.css'
import classNames from 'classnames'
import Weee from '@src/assets/clients/weee.svg'
import HolidayInn from '@src/assets/clients/holiday-inn.svg'
import Amazon from '@src/assets/clients/amazon.svg'
import Instacart from '@src/assets/clients/instacart.svg'
import SRG from '@src/assets/clients/srg.svg'
import ImperfectFoods from '@src/assets/clients/imperfect-foods.svg'

import WeeeDark from '@src/assets/clients/weee-dark.svg'
import HolidayInnDark from '@src/assets/clients/holiday-inn-dark.svg'
import AmazonDark from '@src/assets/clients/amazon-dark.svg'
import InstacartDark from '@src/assets/clients/instacart-dark.svg'
import SRGDark from '@src/assets/clients/srg-dark.svg'
import ImperfectFoodsDark from '@src/assets/clients/imperfect-foods-dark.svg'
import TuftAndNeedle from '@src/assets/clients/tuft-and-needle.svg'
import TuftAndNeedleDark from '@src/assets/clients/tuft-and-needle-dark.svg'
import BurgerKing from '@src/assets/clients/burgerking.svg'
import RiotGames from '@src/assets/clients/riotgames.svg'
import Blick from '@src/assets/clients/dickblick.svg'

function Clients({ isB2b }: { isB2b?: boolean }) {
  return (
    <section className={classNames(classes.container, { [classes.containerB2b]: isB2b })}>
      <div className={classes.mainContent}>
        <h4 className={classNames(classes.heading, { [classes.headingB2b]: isB2b })}>
          {isB2b ? 'Partnerships' : 'Proudly Serving'}
        </h4>
        {isB2b && (
          <p className={classes.description}>
            Sustainability and superior service is achieved when we work together with the
            brightest minds in the industry. Dimension employs a network of thousands of providers
            ready to assist your trash, waste disposal and recycling needs.
          </p>
        )}
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={isB2b ? WeeeDark.src : Weee.src} alt='Weee logo' />
          <img
            className={classes.logo}
            src={isB2b ? HolidayInnDark.src : HolidayInn.src}
            alt='Holiday Inn logo'
          />
          <img
            className={classes.logo}
            src={isB2b ? AmazonDark.src : Amazon.src}
            alt='Amazon logo'
          />
          <img
            className={classes.logo}
            src={isB2b ? InstacartDark.src : Instacart.src}
            alt='Instacart logo'
          />
          <img
            className={classes.logo}
            src={isB2b ? SRGDark.src : SRG.src}
            alt='Sares Regis Group logo'
          />
          <img
            className={classes.logo}
            src={isB2b ? ImperfectFoodsDark.src : ImperfectFoods.src}
            alt='Amazon logo'
          />
          <img
            className={classes.logo}
            src={isB2b ? TuftAndNeedleDark.src : TuftAndNeedle.src}
            alt='Tuft And Needle logo'
          />
          <img
            className={isB2b ? classes.logoNormal : classes.logoDark}
            src={BurgerKing.src}
            alt='Burger King'
          />
          <img
            className={isB2b ? classes.logoNormal : classes.logoDark}
            src={RiotGames.src}
            alt='Riot Games'
          />
          <img
            className={isB2b ? classes.logoNormal : classes.logoDark}
            src={Blick.src}
            alt='Blick'
          />
        </div>
      </div>
    </section>
  )
}

export default Clients
