import React from 'react'
import classes from '@src/styles/CaseStudies.module.css'

import Amazon from '@src/assets/clients/amazon-color.svg'
import Instacart from '@src/assets/clients/instacart-color.svg'

import AmazonCase from '@src/assets/amazon-case.jpg'
import InstacartCase from '@src/assets/instacart-case.jpg'
import Link from 'next/link'
import { segmentTrack } from '@src/functions/segment'

function CaseStudies() {
  const handleClick = (tracker: string) => {
    // eslint-disable-next-line no-console
    console.log('handleClick : tracker', tracker)
    segmentTrack('Click', {
      button: tracker,
    })
  }

  return (
    <section className={classes.mainContainer}>
      <div className={classes.mainContent}>
        <h2 className={classes.heading}>Case Studies</h2>
        <div className={classes.studiesContainer}>
          <div className={classes.case}>
            <img className={classes.caseImage} src={AmazonCase.src} alt='Amazon company' />
            <img
              className={classes.brandLogoAmazon}
              src={Amazon.src}
              id='amazon-logo'
              alt='amazon logo'
            />
            <p className={classes.description}>
              We provide on-demand waste and cardboard removal for a number of Amazon warehouses,
              providing an efficient, reliable solution to their unpredictable loads.
            </p>
            <Link
              href='/amazon_case_study'
              className={classes.readMoreButton}
              onClick={() => handleClick('Read More - Amazon')}
            >
              Read More
            </Link>
          </div>
          <div className={classes.case}>
            <img className={classes.caseImage} src={InstacartCase.src} alt='instacart company' />
            <img className={classes.brandLogo} src={Instacart.src} alt='instacart logo' />
            <p className={classes.description}>
              We provide Instacart with customized solutions to ensure that their nationwide
              warehouses each receive a service that’s tailor-built to fit their needs.
            </p>
            <Link
              href='/case-studies/instacart'
              className={classes.readMoreButton}
              onClick={() => handleClick('Read More - Instacart')}
            >
              Read More
            </Link>
          </div>
        </div>
        <Link
          href='/case-studies'
          className={classes.moreStudiesButton}
          onClick={() => handleClick('Read More - Case Studies')}
        >
          More Case Studies
        </Link>
      </div>
    </section>
  )
}

export default CaseStudies
