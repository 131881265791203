import React from 'react'
import classes from '@src/styles/Solutions.module.css'
import JunkRemoval from '@src/assets/waste-removal.svg'
import DumpsterRental from '@src/assets/dumpster-rental.svg'
import classNames from 'classnames'
import businessSolutionClasses from '@src/styles/BusinessServices.module.css'
import Link from 'next/link'
import { useRouter } from 'next/router'

function Solutions() {
  const router = useRouter();
  const { gclid } = router.query;
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <h2 className={classes.heading}>
          Whatever you need, <br />
          we have the solution
        </h2>
        <div className={businessSolutionClasses.boxContainer}>
          <div className={businessSolutionClasses.box}>
            <div className={businessSolutionClasses.boxContent}>
              <div>
                <img src={JunkRemoval.src} alt='Waste removal' />
                <h3 className={classNames(classes.heading, classes.smallHeading)}>
                  Waste and Recyclables removal
                </h3>
              </div>
              <ul className={classes.boxList}>
                <li className={classes.boxItem}>
                  Completely digital and on demand - solve your waste management worries in minutes.
                </li>
                <li className={classes.boxItem}>
                  Fully customizable solutions - whether you&apos;re looking for a one-off pick up
                  or a recurring solution, we&apos;ve got you covered.
                </li>
                <li className={classes.boxItem}>
                  Easy and reliable - we&apos;ll connect you with the providers best equipped to
                  handle your job, leaving you free to focus on what matters.
                </li>
              </ul>`
              <Link href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&redirect=1&gclid=${gclid ?? ""}`} className={classes.primaryButton}>
                Book Now
              </Link>
            </div>
          </div>
          <div className={businessSolutionClasses.box}>
            <div className={classes.boxContent}>
              <div>
                <img src={DumpsterRental.src} alt='Dumpster Rental' />
                <h3 className={classNames(classes.heading, classes.smallHeading)}>
                  Dumpster Rental
                </h3>
              </div>
              <ul className={classes.boxList}>
                <li className={classes.boxItem}>
                  Fast and Flexible - choose from our wide selection of dumpsters and trailers, with
                  delivery as soon as the next day.
                </li>
                <li className={classes.boxItem}>
                  Hassle free - our extensive diversion data and trusted providers give you the
                  peace of mind that your waste will be managed responsibly.
                </li>
                <li className={classes.boxItem}>
                  On your terms - whether you need a unit for a day or a monthly rental with routine
                  pickups, we can find you the perfect solution.
                </li>
              </ul>
              <Link
                href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&redirect=1&task=dumpster-rental&gclid=${gclid ?? ""}`}
                className={classes.primaryButton}
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Solutions
