import React, { useEffect, useRef, useState } from 'react'
import classes from '@src/styles/FeatureScroller.module.css'
import classNames from 'classnames'
import useIntersectionObserver from '@src/hooks/useIntersectionObserver'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'

type ContentProps = { isMobile?: boolean; feature: any; featureIndex?: number; setImageIndex: any }

function Content({ isMobile, feature, featureIndex, setImageIndex }: ContentProps) {
  const history = useRouter()
  const { gclid } = history.query;
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible) {
      setImageIndex(featureIndex)
    }
  }, [featureIndex, isVisible, setImageIndex])

  const mobileContentStyles =
    isMobile && feature.backgroundColor
      ? { backgroundColor: feature.backgroundColor, width: '100%' }
      : {}

  return (
    <div
      style={mobileContentStyles}
      className={classNames(classes.content, { [classes.contentMobile]: isMobile })}
    >
      {isMobile && (
        <img
          alt=''
          src={feature.imageUrl}
          className={classNames(classes.rightImage, classes.rightImageMobile)}
        />
      )}
      <h2 ref={ref} className={classes.header}>
        {feature.title}
      </h2>
      {feature.subTitle && <h4 className={classes.subHeader}>{feature.subTitle}</h4>}
      {feature.descriptions.map((description: string) => (
        <p key={description} className={classes.description}>
          {description}
        </p>
      ))}
      {feature.buttons && feature?.buttons(gclid)}
    </div>
  )
}

function FeatureScroller({ features = [] }: { features: Array<any> }) {
  const [imageIndex, setImageIndex] = useState(0)
  const mobile = useMediaQuery({ query: '(max-width:959.95px)'});
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);
  return isMobile ? (
    <div className={classes.contentContainerMobile}>
      {features.map((feature: any) => (
        <Content isMobile key={feature.title} feature={feature} setImageIndex={setImageIndex} />
      ))}
    </div>
  ) : (
    <div className={classes.featuresWrapper}>
      <div className={classes.featuresColumnLeft}>
        {features.map((feature: any, idx: number) => (
          <Content
            key={feature.title}
            feature={feature}
            setImageIndex={setImageIndex}
            featureIndex={idx}
          />
        ))}
      </div>
      <div className={classes.featuresColumnRight}>
        {features.map((feature: any, idx: number) => (
          <div
            style={{ opacity: imageIndex === idx ? 1 : 0 }}
            key={feature.title + feature.imageUrl}
            className={classes.featureIllustrationWrapper}
          >
            <img alt='' src={feature.imageUrl} className={classes.rightImage} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeatureScroller
